import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import useAddClassToHtml from 'hooks/useAddClassToHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import ArticleBody from 'components/article/ArticleBody';
import ArticleDisclaimer from 'components/article/ArticleDisclaimer';
import PageIntro from 'components/elements/PageIntro';
import ScrollButton from 'components/elements/ScrollButton';
import TaggedLink from 'components/elements/TaggedLink';
import Typography from 'components/elements/Typography';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/CondidionalWrapper';
import DynamicImage from 'components/helpers/DynamicImage';
import StickyWrapper from 'components/helpers/StickyWrapper';
import FaqInfo from 'components/sections/FaqInfo';
import OrderedList from 'components/sections/OrderedList';
import RecommendedArticles from 'components/sections/RecommendedArticles';
import RecommendedProducts from 'components/sections/RecommendedProducts';
import { SMOOTH_SCROLL_CLASSNAME } from 'utils/constants';

import { handleArticleBodyNavigation } from './helpers';
import { IPropsArticlePage } from './models';

import './ArticlePage.scss';

const ArticlePage: FC<IPropsArticlePage> = ({
  data: {
    pageData,
    recommendedArticlesByLink,
    recommendedArticlesByTag,
    recommendedProductsByLink,
    recommendedProductsByTag,
    poolProductsByLink,
  },
  pageContext,
}) => {
  const {
    seo,
    urls,
    titleToDisplay,
    descriptionToDisplay,
    mainImage,
    articleBody,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    faq,
    disclaimer,
    recommendedArticles,
    recommendedProducts,
    taggedLinksList,
    sources,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;
  const { isNotMobile, isInit } = useScreenRecognition();
  // hook adds smooth scroll behavior to page html tag, useful for anchor links
  useAddClassToHtml({ className: SMOOTH_SCROLL_CLASSNAME });
  const articleSecondaryNavigation = [
    ...articleBody,
    ...(faq?.length ? faq : []),
    ...(sources?.length ? sources : []),
  ];
  const articleBodyNavigation = useMemo(
    () => handleArticleBodyNavigation(articleSecondaryNavigation),
    [articleBody, faq, sources]
  );

  return (
    <Layout
      data-testid="ArticlePage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="article-page"
      pageSchemaType="Article"
    >
      <PageIntro
        breadcrumbs={breadcrumbs}
        showBreadcrumbsDesktop={showBreadcrumbsDesktop}
        showBreadcrumbsMobile={showBreadcrumbsMobile}
      />
      <Container>
        <article className="article-page__content">
          <Typography data={titleToDisplay} customClass="article-page__title" />
          {taggedLinksList ? (
            <div className="article-page__tags">
              {taggedLinksList.map((category) => (
                <TaggedLink data={category} key={category.url[0].name} />
              ))}
            </div>
          ) : null}
          <Typography data={descriptionToDisplay} customClass="article-page__description" />
          {articleBodyNavigation?.length ? (
            <ul className="article-page__navigation">
              {articleBodyNavigation.map((item) => (
                <li key={item.navigationName}>
                  <ScrollButton navigationName={item.navigationName} scrollOffset={20} />
                </li>
              ))}
            </ul>
          ) : null}
          <DynamicImage
            image={mainImage}
            className="article-page__main-image"
            isLazyLoading={false}
          />
          <ArticleBody articleBody={articleBody} poolProductsByLink={poolProductsByLink?.nodes} />
          {faq?.length ? <FaqInfo data={faq} /> : null}
          <ConditionalWrapper
            condition={isNotMobile || isInit}
            wrapper={
              //  eslint-disable-next-line react/no-unstable-nested-components
              (children) => (
                <StickyWrapper className="article-page__sticky" isSetSticky={isNotMobile}>
                  <div className="article-page__sidebar">{children}</div>
                </StickyWrapper>
              )
            }
          >
            <>
              {disclaimer ? (
                <ArticleDisclaimer data={disclaimer} className="article-page__disclaimer" />
              ) : null}
              {recommendedArticlesByLink?.nodes[0] || recommendedArticlesByTag?.nodes[0] ? (
                <RecommendedArticles
                  data={recommendedArticles}
                  recommendedArticlesByLink={recommendedArticlesByLink?.nodes}
                  recommendedArticlesByTag={recommendedArticlesByTag?.nodes}
                />
              ) : null}
              {recommendedProductsByLink?.nodes[0] || recommendedProductsByTag?.nodes[0] ? (
                <RecommendedProducts
                  data={recommendedProducts}
                  recommendedProductsByLink={recommendedProductsByLink?.nodes}
                  recommendedProductsByTag={recommendedProductsByTag?.nodes}
                />
              ) : null}
            </>
          </ConditionalWrapper>
          {sources?.length ? <OrderedList data={sources} /> : null}
        </article>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query (
    $url: String!
    $lang: String
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductsTag: Int
    $selectedProductsLinksInBody: [String]
  ) {
    pageData: articlePage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentArticlePage
    }
    recommendedArticlesByLink: allArticlePage(
      filter: { url: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    recommendedArticlesByTag: allArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    recommendedProductsByLink: allProductPage(
      filter: { url: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    recommendedProductsByTag: allProductPage(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductsTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    poolProductsByLink: allProductPage(
      filter: { url: { in: $selectedProductsLinksInBody }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
  }
`;

export default ArticlePage;
